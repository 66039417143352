<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车道id" prop="laneId" >
        <a-input v-model="form.laneId" placeholder="请输入车道id" />
      </a-form-model-item>
      <a-form-model-item label="路线ID" prop="routeId" >
        <a-input v-model="form.routeId" placeholder="请输入路线ID" />
      </a-form-model-item>
      <a-form-model-item label="作业类型" prop="taskType" >
        <a-select placeholder="请选择作业类型" v-model="form.taskType">
          <a-select-option v-for="(d, index) in taskTypeOptions" :key="index" :value="d.dictValue" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="行驶位置" prop="drivingPosition" >
        <a-select placeholder="请选择行驶位置" v-model="form.drivingPosition">
          <a-select-option v-for="(d, index) in drivingPositionOptions" :key="index" :value="d.dictValue" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="速度(kph)" prop="speedLimit" >
        <a-input v-model="form.speedLimit" placeholder="请输入速度(kph)" />
      </a-form-model-item>
      <a-form-model-item label="车道顺序" prop="orderNum" >
        <a-input v-model="form.orderNum" placeholder="请输入车道顺序" />
      </a-form-model-item>
      <a-form-model-item label="车道状态" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLanePlanning, addLanePlanning, updateLanePlanning } from '@/api/iot/lanePlanning'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    },
    taskTypeOptions: {
      type: Array,
      required: true
    },
    drivingPositionOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        laneId: null,
        routeId: null,
        taskType: null,
        drivingPosition: null,
        speedLimit: null,
        orderNum: 0,
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        laneId: [
          { required: true, message: '车道id不能为空', trigger: 'blur' }
        ],
        // routeId: [
        //   { required: true, message: '路线ID不能为空', trigger: 'blur' }
        // ],
        taskType: [
          { required: true, message: '作业类型不能为空', trigger: 'change' }
        ],
        drivingPosition: [
          { required: true, message: '行驶位置不能为空', trigger: 'change' }
        ],
        speedLimit: [
          { required: true, message: '速度(kph)不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        laneId: null,
        routeId: null,
        taskType: null,
        drivingPosition: null,
        speedLimit: null,
        orderNum: 0,
        status: 0
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLanePlanning(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLanePlanning(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addLanePlanning(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
