import request from '@/utils/request'

// 查询车道规划列表
export function listLanePlanning (query) {
  return request({
    url: '/iot/lanePlanning/list',
    method: 'get',
    params: query
  })
}

// 查询车道规划详细
export function getLanePlanning (id) {
  return request({
    url: '/iot/lanePlanning/' + id,
    method: 'get'
  })
}

// 新增车道规划
export function addLanePlanning (data) {
  return request({
    url: '/iot/lanePlanning',
    method: 'post',
    data: data
  })
}

// 修改车道规划
export function updateLanePlanning (data) {
  return request({
    url: '/iot/lanePlanning',
    method: 'put',
    data: data
  })
}

// 删除车道规划
export function delLanePlanning (id) {
  return request({
    url: '/iot/lanePlanning/' + id,
    method: 'delete'
  })
}

// 导出车道规划
export function exportLanePlanning (query) {
  return request({
    url: '/iot/lanePlanning/export',
    method: 'get',
    params: query
  })
}
